'use client';
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useAccount } from 'wagmi';
import toast from 'react-hot-toast';
import { Identity, useFollowIdentity, useIdentity, useUpdateIdentity } from 'services/identity';
import { zeroAddress } from 'viem';
import { useAuthContext } from './auth';
import { defaultBio } from '~/identity/bio';

export const sadPepe = 'https://immutable-cdn.s3.us-east-2.amazonaws.com/identity-icons/sad-pepe.png';
export const shockPepe = 'https://immutable-cdn.s3.us-east-2.amazonaws.com/identity-icons/shock-pepe.png';
export const yepPepe = 'https://immutable-cdn.s3.us-east-2.amazonaws.com/identity-icons/yep-pepe.png';

export const defaultIdentity: Identity = {
  id: 0,
  address: zeroAddress,
  bio: defaultBio,
  username: 'Anonymous',
  avatar: sadPepe,
  dateCreated: new Date(),
  dateUpdated: new Date(),
};

type IdentityContextType = {
  identity: {
    identity: Identity;
    isFetching: boolean;
    isError: boolean;
  };
  updateIdentity: (params: Partial<Identity>) => void;
  followIdentity: (address: string, follow: boolean) => void;
};

const IdentityContext = createContext<IdentityContextType>({
  identity: {
    identity: defaultIdentity,
    isFetching: false,
    isError: false,
  },
  updateIdentity: () => {
    throw new Error('updateIdentity not implemented');
  },
  followIdentity: () => {
    throw new Error('followIdentity not implemented');
  },
});

function IdentityContextProvider({ children }: PropsWithChildren) {
  const { address } = useAccount();
  const { data: identity, isFetching, isError } = useIdentity(address);

  const { mutateAsync } = useUpdateIdentity();
  const { mutateAsync: callFollowIdentity } = useFollowIdentity();

  const { authenticated } = useAuthContext();

  const contextValue: IdentityContextType = useMemo(
    () => ({
      identity: {
        identity: identity || defaultIdentity,
        isFetching,
        isError,
      },
      updateIdentity: async function updateIdentity({
        username,
        avatar,
        bio,
      }: {
        username?: string;
        avatar?: string;
        bio?: string;
      }) {
        if (!address) {
          console.error('No address found');
          return;
        }

        try {
          if (!authenticated.token) {
            toast.error('Not authenticated');
            return;
          }
          await mutateAsync({ authToken: authenticated.token, address, username, avatar, bio });
        } catch (e) {
          console.error(e);
          toast.error('Error updating identity');
        }
      },
      followIdentity: async function followIdentity(address: string, follow: boolean) {
        if (!authenticated.token) {
          toast.error('Not authenticated');
          return;
        }

        try {
          await callFollowIdentity({
            authToken: authenticated.token,
            follower: authenticated.address,
            followed: address,
            follow,
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),
    [
      address,
      authenticated.address,
      authenticated.token,
      callFollowIdentity,
      identity,
      isError,
      isFetching,
      mutateAsync,
    ],
  );

  const Provider = IdentityContext.Provider;

  return <Provider value={contextValue}>{children}</Provider>;
}

function useIdentityContext() {
  const context = useContext(IdentityContext);
  if (!context) {
    throw new Error('uuseIdentityContext must be used within an IdentityContextProvider');
  }
  return context;
}

export { IdentityContextProvider, useIdentityContext };
