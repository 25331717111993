import { Control, FieldErrors } from 'react-hook-form';
import { cn } from 'utils/utils';
import { Card, CardContent, CardHeader, CardTitle } from '~/ui/card';
import { FormControl, FormField, FormItem, FormMessage } from '~/ui/form';
import { Input } from '~/ui/input';

const inputWidth = 'max-w-[150px] w-[150px] min-w-[150px]';

type IdentityBioProps = {
  isEditing?: boolean;
  bio?: string;
  control?: Control<any>;
  errors?: FieldErrors<any>;
};
export const defaultBio = 'No bio...';
function IdentityBio({ isEditing = false, bio, control, errors }: IdentityBioProps) {
  if (isEditing && control) {
    return (
      <FormField
        control={control}
        name='bio'
        render={({ field }) => (
          <Card className='rounded-none'>
            <FormItem>
              <CardHeader>
                <CardTitle>Bio</CardTitle>
              </CardHeader>
              <CardContent className='py-4'>
                <FormControl>
                  <Input className='text-lg' {...field} defaultValue={bio} placeholder={defaultBio} />
                </FormControl>
                {/* @ts-ignore */}
                <FormMessage>{errors?.name?.message}</FormMessage>
              </CardContent>
            </FormItem>
          </Card>
        )}
      />
    );
  } else {
    return <div className={cn(inputWidth, 'text-sm')}>@{bio || defaultBio}</div>;
  }
}

export default IdentityBio;
