import { apiURL } from './api';

export enum AuthRoutes {
  SIWE_MESSAGE = '/api/v1/auth/siwe-message',
  SIGN_IN = '/api/v1/auth/sign-in',
  SIGN_OUT = '/api/v1/auth/sign-out',
}

export type SiweMessage = {
  domain: string;
  address: string;
  statement: string;
  uri: string;
  version: string;
  chainId: number;
  nonce: string;
  issuedAt: string;
  expirationTime: string;
};

export type VerifySIWEResponse = {
  success: boolean;
  token?: string;
  error?: string;
};

async function getSIWE(address: string): Promise<[string, null] | [null, string]> {
  try {
    const response = await fetch(`${apiURL}${AuthRoutes.SIWE_MESSAGE}/${address}`, {
      method: 'GET',
      // credentials: 'include', // Optional: Remove if not using cookies
    });
    if (!response.ok) {
      console.error('Error fetching SIWE message', response);
      return [null, 'Error fetching SIWE message'];
    }

    const data = await response.json();
    const siweMessage: string = data.message;
    return [siweMessage, null];
  } catch (error) {
    console.error('catch', error);
    return [null, 'Error fetching nonce'];
  }
}

async function verifySIWE(message: string, signature: string): Promise<VerifySIWEResponse> {
  try {
    const response = await fetch(`${apiURL}${AuthRoutes.SIGN_IN}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message, signature }),
      // credentials: 'include', // Optional: Remove if not using cookies
    });

    if (!response.ok) {
      console.error('Error verifying SIWE message', response);
      const errorData = await response.json();
      return { success: false, error: errorData.error || 'Error verifying SIWE message' };
    }

    const data: VerifySIWEResponse = await response.json();
    return data;
  } catch (error) {
    console.error('catch', error);
    return { success: false, error: 'Error verifying SIWE signature' };
  }
}

async function signOut(): Promise<void> {
  try {
    await fetch(`${apiURL}${AuthRoutes.SIGN_OUT}`, {
      method: 'POST',
      // credentials: 'include', // Optional: Remove if not using cookies
    });
    // Since JWTs are stored on the client, sign-out can be handled entirely on the frontend
  } catch (error) {
    console.error(error);
  }
}

export default { getSIWE, verifySIWE, signOut };
