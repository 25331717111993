const API_URL = process.env.NEXT_PUBLIC_API_URL;
if (!API_URL) {
  throw new Error('NEXT_PUBLIC_API_URL is not set');
}

const SOCKET_SERVER_URL = process.env.NEXT_PUBLIC_SOCKET_SERVER_URL;
if (!SOCKET_SERVER_URL) {
  throw new Error('NEXT_PUBLIC_SOCKET_SERVER_URL is not set');
}

export const apiURL = API_URL;
export const socketServerURL = SOCKET_SERVER_URL;
